import React from "react";
import { Button, Paragraph, Text } from "theme-ui";

const ConfirmationEmail = ({ email, onContinue, resendEmail }) => {
  return (
    <>
      <Paragraph sx={{ variant: "text.body1", mb: "40px" }}>
        We’ve sent a verification email to:
        <br />
        <br />
        <Text sx={{ fontWeight: "bold" }}>{email}</Text>
        <br />
        <br />
        Please follow the steps in this email to confirm your details to be able
        to access all areas.
      </Paragraph>

      <Button onClick={onContinue} sx={{ mb: 4 }}>
        Continue
      </Button>
      <Button variant="outlined" onClick={resendEmail}>
        Resend verification email
      </Button>
    </>
  );
};

export default ConfirmationEmail;
