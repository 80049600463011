export const modalContainer = () => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  minHeight: "100vh",
  minWidth: "100vw",

  zIndex: 3000,
  backgroundColor: "rgba(0,0,0,0.5)",

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  mx: "0 !important",
});
