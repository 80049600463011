import { useContext, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import themeBase from "gatsby-plugin-theme-ui";
import { AuthContext } from "Authentication";

let Smooch;

const useWebchat = (propertyCode, name) => {
  const { strapiRecommendsConfig, strapiBrandHome } = useStaticQuery(graphql`
    query RecommendsConfigQuery {
      strapiRecommendsConfig {
        smooch_integration_id
      }
      strapiBrandHome {
        brand_name
      }
    }
  `);

  const { profile } = useContext(AuthContext);

  useEffect(() => {
    if (!profile) {
      Smooch?.destroy();
      return;
    }
    if (!Smooch) {
      import("smooch").then((lib) => {
        Smooch = lib.default;
        webChat({
          integrationId: strapiRecommendsConfig.smooch_integration_id,
          brandName: strapiBrandHome.brand_name,
          name,
          propertyCode,
          profile,
        });
      });
    }
    webChat({
      integrationId: strapiRecommendsConfig.smooch_integration_id,
      brandName: strapiBrandHome.brand_name,
      name,
      propertyCode,
      profile,
    });

    return () => {
      Smooch?.destroy();
    };
  }, [profile]);
};

export const webChat = ({
  integrationId,
  brandName,
  name,
  propertyCode,
  profile,
}) => {
  const smoochCredentialsPresent = profile.smoochJwt && profile.smoochUserId;
  Smooch?.init({
    integrationId,
    businessName: brandName,
    displayStyle: "button",
    externalId: smoochCredentialsPresent ? profile.smoochUserId : undefined,
    jwt: smoochCredentialsPresent ? profile.smoochJwt : "",
    integrationOrder: [],
    canUserCreateMoreConversations: false,
    canUserSeeConversationList: false,
    customColors: {
      brandColor: themeBase.colors.primary.__default.replace(/#/g, ""),
      conversationColor: "185172",
      actionColor: "e8e8ef",
    },
    menuItems: {
      imageUpload: true,
      fileUpload: true,
      shareLocation: false,
    },

    delegate: {
      beforeSend(message) {
        message.metadata = {
          property_name: name,
          short_property_code: propertyCode,
          webpage_url: window.location.href,
          profile_id: profile.id,
        };
        return message;
      },

      beforeDisplay(message) {
        if (message.deleted) return null;
        if (!message.metadata) return null;
        return message.metadata["short_property_code"] == propertyCode
          ? message
          : null;
      },
    },
  });
};

export default useWebchat;
