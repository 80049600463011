import React from "react";
import EmailField from "components/Fields/Email";
import PasswordField from "components/Fields/Password";
import { Button, Flex } from "theme-ui";
import { useForm } from "react-hook-form";

const WithEmailForm = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  return (
    <Flex
      as="form"
      sx={{
        flexDirection: "column",
        gap: 4,
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <EmailField
        label="Email"
        register={register("email", { required: true })}
      />
      <PasswordField
        label="Password"
        register={register("password", { required: true })}
        styles={{ mb: 10 }}
      />

      <Button type="submit">Continue</Button>
    </Flex>
  );
};

export default WithEmailForm;
