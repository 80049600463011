export default {
  black_background: {
    background:
      "linear-gradient(180deg, rgba(18, 18, 18, 0) 50%, rgba(18, 18, 18, 0.8) 100%)",
    borderRadius: 1,
    overflow: "hidden",
    "&:hover": {
      boxShadow: "elevation3dp",
    },
  },

  white: {
    display: "flex",
    height: "100%",
    bg: "componentBG1",
    borderRadius: 1,
    overflow: "hidden",
    boxShadow: "elevation1dp",
    "&:hover": {
      boxShadow: "elevation3dp",
    },
    transform: "translateZ(0)",
  },

  no_background: {
    display: "flex",
    height: "100%",
    bg: "none",
    borderRadius: 1,
    overflow: "hidden",
    transform: "translateZ(0)",
  },

  outlined: {
    display: "flex",
    height: "100%",
    bg: "componentBG1",
    border: "1px solid",
    borderColor: "lineDetail",
    borderRadius: 1,
    overflow: "hidden",
    "&:hover": {
      boxShadow: "elevation1dp",
    },
    transform: "translateZ(0)",
  },

  children: {
    width: "100%",
    px: 6,
    py: 3,
  },

  children_white: {
    variant: "cards.children",
    bg: "componentBG1",
  },

  children_no_background: {
    variant: "cards.children",
    bg: "none",
  },

  children_outlined: {
    variant: "cards.children",
    bg: "componentBG1",
  },
};
