import React, { useState } from "react";
import Alert from "components/Alert";
import { useEffect } from "react";

const FloatingAlert = ({ styles, isOpen, onClose, ...props }) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
    setTimeout(() => onClose(), 5000);
  }, [isOpen]);

  const onCloseHandler = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Alert
      styles={{
        position: "fixed",
        bottom: "0",
        maxWidth: 80,
        transform: open ? "translateY(-10px)" : "translateY(10px)",
        opacity: open ? 1 : 0,
        transition: "all ease-out .1s",
        ...styles,
      }}
      onClose={onCloseHandler}
      {...props}
    />
  );
};

export default FloatingAlert;
