import { StaticImage } from "gatsby-plugin-image";
import React, { memo, useEffect, useRef } from "react";
import { Box, Image } from "theme-ui";
import {
  getStaticMapURL,
  getInteractiveMapUrl,
  loadMap,
  addMarker,
} from "utils";

const InteractiveMap = ({ coordinates, marker, ...props }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      loadMap(ref.current, {
        center: { lat: coordinates.latitude, lng: coordinates.longitude },
      }).then(addMarker(marker));
    }
  }, [ref.current]);

  return <Box ref={ref} {...props} />;
};

const GoogleMap = ({
  coordinates,
  marker,
  staticMap = false,
  sx = {},
  ...props
}) => {
  return staticMap ? (
    <Image
      src={getStaticMapURL(coordinates, marker)}
      sx={{
        width: "full",
        height: 80,
        objectFit: "cover",
        ...sx,
      }}
      {...props}
    />
  ) : (
    <InteractiveMap
      coordinates={coordinates}
      marker={marker}
      sx={sx}
      {...props}
    />
  );
};

export default memo(GoogleMap);
