import React from "react";
import Frame from "./Frame";
import { Input } from "theme-ui";

const PasswordInput = ({ register, ...props }) => {
  return (
    <Frame {...props} name={register.name}>
      <Input id={register.name} placeholder=" " type="password" {...register} />
    </Frame>
  );
};

export default PasswordInput;
