import React, { useContext } from "react";
import { navigate } from "gatsby";
import { AuthContext } from "../Authentication/AuthProvider";
import { isLogedIn } from "../Authentication/firebase";

const PrivateRoute = ({ component: Component, ...rest }) => {
  if (!isLogedIn() && location.pathname !== `/`) {
    navigate("/", {
      state: {
        afterSignInPath: location.pathname,
      },
    });
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
