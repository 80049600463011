import React from "react";
import EmailField from "components/Fields/Email";
import PasswordField from "components/Fields/Password";
import { Button, Flex } from "theme-ui";
import { useForm } from "react-hook-form";
import Link from "components/Link";

const WithLoyaltyForm = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      identifier: "",
      password: "",
    },
  });
  return (
    <Flex
      as="form"
      sx={{
        flexDirection: "column",
        gap: 4,
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <EmailField
        label="Member ID or email address"
        register={register("identifier", { required: true })}
      />
      <PasswordField
        label="Password"
        register={register("password", { required: true })}
        styles={{ mb: 10 }}
        helper="At least 8 characters in length"
      />

      <Button type="submit">Log in as Fan of M.O.</Button>

      <Link
        variant="buttons.text"
        sx={{ mt: 6, mb: 12, textAlign: "center" }}
        to="https://www.mandarinoriental.com/en/fans-of-mo/registration"
      >
        Not a member yet? Get started
      </Link>
    </Flex>
  );
};

export default WithLoyaltyForm;
