export default {
  error: {
    bg: "error",
    color: "error.contrastText",
    boxShadow: "elevation5dp",
  },
  info: {
    bg: "info",
    color: "info.contrastText",
    boxShadow: "elevation5dp",
  },
  success: {
    bg: "success",
    color: "success.contrastText",
    boxShadow: "elevation5dp",
  },
  warnign: {
    bg: "warning",
    color: "success.contrastText",
    boxShadow: "elevation5dp",
  },
};
