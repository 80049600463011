import React from "react";
import { Text } from "theme-ui";
import Link from "./Link";

const LinkWithIcon = ({ icon: Icon, sx, children, ...props }) => (
  <Link
    sx={{
      display: "inline-flex",
      alignItems: "center",
      ...sx,
    }}
    {...props}
  >
    {children}
    <Text sx={{ fontSize: 5, display: "inline-flex" }}>
      <Icon />
    </Text>
  </Link>
);

export default LinkWithIcon;
