import { alpha } from "@theme-ui/color";
import { buttonSize } from "./utils";

export default {
  primary: buttonSize({
    transition: "all ease-in-out 0.2s",
    borderRadius: "rounded",
    cursor: "pointer",
    color: "primary.contrastText",
    background: "primary",

    "&:hover": {
      background: "primary.dark",
    },
    "&:active, &.isSelected": {
      background: "primary.light",
    },
    "&:disabled": {
      background: "backgroundDisabled",
      color: "text.disabled",
    },
  }),
  text: buttonSize({
    color: "primary",
    background: "transparent",
    borderRadius: "rounded",
    textAling: "center",
    cursor: "pointer",

    "&:hover": {
      background: "primary.hover",
    },
    "&:active, &.isSelected": {
      background: "primary.selected",
      color: "primary.dark",
    },
    "&:disabled": {
      background: "transparent",
      color: "text.disabled",
    },
  }),
  outlined: buttonSize({
    color: "primary",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "primary.border",
    background: "transparent",
    borderRadius: "rounded",
    cursor: "pointer",

    "&:hover": {
      background: "primary.hover",
    },
    "&:active, &.isSelected": {
      background: "primary.selected",
      color: "primary.dark",
    },
    "&:disabled": {
      background: "transparent",
      borderColor: "lineDetail",
      color: "text.disabled",
      cursor: "",
    },
  }),
  blackOutlined: buttonSize({
    color: "grey.1000",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: alpha("grey.1000", 0.6),
    background: "transparent",
    borderRadius: "rounded",
    cursor: "pointer",

    "&:hover": {
      background: "primary.hover",
    },
    "&:active, &.isSelected": {
      background: "primary.selected",
    },
    "&:disabled": {
      background: "transparent",
      borderColor: "lineDetail",
      color: "text.disabled",
      cursor: "",
    },
  }),
  segment: {
    variant: "text.button1",
    display: "inline-flex",
    px: 0,
    py: 2,
    background: "transparent",
    color: "text.secondary",
    cursor: "pointer",
    "&:hover": {
      color: "text",
    },
    "&:active, &.isSelected": {
      color: "primary",
    },
    "& svg": {
      mr: 2,
      fontSize: 5,
    },
  },
  onDark: {
    variant: "text.button1",
    display: "flex",
    justifyContent: "center",
    color: "systemWhite",
    border: "none",
    bg: "backdrop",
    borderRadius: "rounded",
    cursor: "pointer",
    lineHeight: ["24px", "28px"],
    zIndex: "low",

    "&:hover": {
      text: alpha("systemWhite", 0.6),
    },
    "&:disabled": {
      background: "lineDetail",
      color: "text.disabled",
      cursor: "",
    },
  },
  icon: {
    solid: {
      display: "inline-flex",
      width: "unset",
      height: "unset",
      p: 3,
      background: "primary.contrastText",
      borderRadius: "full",
      boxShadow: "elevation1dp",
      color: "primary",
      fontSize: 6,
      cursor: "pointer",
      "&.fas": {
        width: "2em",
        height: "2em",
      },
      "&:disabled": {
        background: "disabledBackground",
        color: "text.disabled",
        current: "auto",
      },
    },
    light: {
      display: "inline-flex",
      width: "unset",
      height: "unset",
      p: 3,
      background: "transparent",
      borderRadius: "full",
      color: "primary",
      fontSize: 6,
      cursor: "pointer",

      "&:disabled": {
        color: "text.disabled",
        current: "auto",
      },
    },
    withoutBackground: {
      display: "inline-flex",
      width: "unset",
      height: "unset",
      p: 3,
      borderRadius: 0,
      color: "primary",
      fontSize: 6,
      cursor: "pointer",

      "&:disabled": {
        color: "text.disabled",
        current: "auto",
      },
    },
  },
};
