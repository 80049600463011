import themeBase from "gatsby-plugin-theme-ui";
import { Loader } from "@googlemaps/js-api-loader";
import markerLogo from "../images/marker_logo.png";
import { getGoogleMapApiKey } from "./envvars";

const STATIC_MAP_URL = "https://maps.googleapis.com/maps/api/staticmap";
const INTERACTIVE_MAP_URL = "https://www.google.com/maps/embed/v1/view";
const EXTERNAL_MAP_URL = "https://www.google.com/maps/@";

const ZOOM = 15;

let loader = new Loader({
  apiKey: getGoogleMapApiKey(),
  version: "weekly",
  libraries: ["places"],
});
let map;

export function loadMap(element, options) {
  return loader.load().then(() => {
    map = new google.maps.Map(element, {
      zoom: ZOOM,

      ...options,
    });
    return map;
  });
}

export function addMarker(query) {
  return (map) => {
    var request = {
      location: map.getCenter(),
      radius: "500",
      query,
    };

    var service = new google.maps.places.PlacesService(map);
    service.textSearch(request, (results, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        new google.maps.Marker({
          map: map,
          place: {
            placeId: results[0].place_id,
            location: results[0].geometry.location,
          },
          icon: markerLogo,
        });
      }
    });
  };
}

export function getStaticMapURL(
  coordinates,
  marker,
  size = "600x160",
  scale = 2
) {
  const url = new URL(STATIC_MAP_URL);
  url.search = new URLSearchParams({
    center: coordinates.latitude + "," + coordinates.longitude,
    markers: marker,
    zoom: ZOOM,
    size,
    scale,
    key,
  });
  return url.toString();
}
export function getInteractiveMapUrl(coordinates) {
  const url = new URL(INTERACTIVE_MAP_URL);
  url.search = new URLSearchParams({
    center: coordinates.latitude + "," + coordinates.longitude,
    zoom: ZOOM,
    key,
  });
  return url.toString();
}

export function getExternalMapUrl(coordinates) {
  const url = new URL(EXTERNAL_MAP_URL);
  url.search = new URLSearchParams({
    api: 1,
    map_action: "map",
    center: coordinates.latitude + "," + coordinates.longitude,
    zoom: ZOOM,
  });
  return url.toString();
}

export function generateMarkerForLocation(locationInfo) {
  let marker = locationInfo.name;

  if (locationInfo.contactInfo?.address?.city) {
    marker += ", " + locationInfo.contactInfo.address.city;
  }

  return marker;
}
