import React from "react";
import Frame from "./Frame";
import { Input } from "theme-ui";

const TextInput = ({ register, ...props }) => {
  return (
    <Frame {...props} name={register.name}>
      <Input id={register.name} placeholder=" " type="text" {...register} />
    </Frame>
  );
};

export default TextInput;
