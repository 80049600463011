import React from "react";
import EmailFields from "components/Fields/Email";
import TextField from "components/Fields/Text";
import { Button, Flex, Text } from "theme-ui";
import { useForm } from "react-hook-form";

const BookingDetailsForm = ({ onSubmit, prevStep }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      confirmationNumber: "",
    },
  });
  return (
    <Flex
      as="form"
      sx={{
        flexDirection: "column",
        gap: 2,
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <EmailFields
        label="Email"
        register={register("email", { required: true })}
        helper="Enter the email address you booked with."
      />
      <TextField
        label="Confirmation Number"
        register={register("confirmationNumber", { required: true })}
        helper="You’ll find this on your confirmation email."
        styles={{
          mb: 10,
        }}
      />

      <Button type="submit">Log in</Button>
      <Button type="button" variant="buttons.text" onClick={prevStep}>
        Log in without a booking
      </Button>
    </Flex>
  );
};

export default BookingDetailsForm;
