import React from "react";
import { Button, Text } from "theme-ui";

const ButtonWithIcon = ({
  icon,
  children,
  sx,
  variant = "buttons.outlined",
  ...props
}) => {
  return (
    <Button
      variant={variant}
      sx={{
        display: "flex",
        textAlign: "center",
        gap: 2,
        ...sx,
      }}
      {...props}
    >
      {icon}
      <Text as="span" sx={{ flex: "1" }}>
        {children}
      </Text>
    </Button>
  );
};

export default ButtonWithIcon;
