import { useEffect } from "react";
import { useState } from "react";
import { useThemeUI } from "theme-ui";
import { debounce } from "debounce";

function variantByBreakpoint(variantList, current) {
  if (current === undefined) return variantList[0] ?? "";

  return (
    (variantList[current] ||
      variantList[current - 1] ||
      variantList[current - 2]) ??
    ""
  );
}

function determinateCurrent(breakpoints) {
  const docWidth = document.body.offsetWidth;
  return breakpoints.reduce((prev, current, index) => {
    return parseInt(current) <= docWidth ? index + 1 : prev;
  }, 0);
}

export default function useVariantByBreakpoint() {
  const {
    theme: { breakpoints },
  } = useThemeUI();
  const [currentBreakpoint, setCurrentBreakpoint] = useState();

  useEffect(() => {
    const callback = debounce(() => {
      const newCurrent = determinateCurrent(breakpoints);
      if (newCurrent !== currentBreakpoint) setCurrentBreakpoint(newCurrent);
    }, 10);
    window.addEventListener("resize", callback);

    return () => {
      window.removeEventListener("resize", callback);
    };
  }, []);

  useEffect(() => {
    setCurrentBreakpoint(determinateCurrent(breakpoints));
  }, [breakpoints]);

  return (variants) => {
    return variantByBreakpoint(variants, currentBreakpoint);
  };
}
