export default {
  __default: {
    variant: "text.body2",
    py: 1,
    px: 4,
    backgroundColor: "primary.hover",
    border: "1px solid transparent",
    borderRadius: "rounded",
    color: "primary",
    cursor: "pointer",
    "&:hover": {
      borderColor: "primary.border",
    },
    "&.isActive": {
      backgroundColor: "primary",
      color: "systemWhite",
    },
  },
  info: {
    variant: "text.body2",
    py: 1,
    px: 4,
    backgroundColor: "info.light",
    border: "1px solid",
    borderColor: "info.light",
    borderRadius: "rounded",
    color: "info.dark",
  },
  nonInteractive: {
    variant: "text.body2",
    py: 1,
    px: 4,
    backgroundColor: "primary.hover",
    border: "1px solid transparent",
    borderRadius: "rounded",
    color: "primary",
  },
  important: {
    variant: "text.body2",
    py: 1,
    px: 4,
    backgroundColor: "warning",
    border: "1px solid",
    borderColor: "warning",
    borderRadius: "rounded",
    color: "warning.contrastText",
  },
};
