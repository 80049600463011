export const SIZES_VALUES = [
  0, 1, 2, 4, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32, 36, 40, 44, 48, 56, 60, 64,
  72, 80, 96, 112, 128, 140, 144, 152, 160, 176, 192, 208, 224, 240, 252, 256,
  260, 288, 320, 340, 344, 352, 360, 376, 384, 400, 420, 460, 480, 540, 600,
  640, 680, 1120, 1440,
];

export const CUSTOM_SIZES_VALUES = {
  "screen-h": "100vh",
  "screen-w": "100vw",
  full: "100%",
  maxContent: "75rem",
  trueMaxContent: "70rem",
  gallery: "90rem",
  contentSpace: "max(calc(50vw - 36rem), 2.5rem)",
  halfScreenSliderCard: "calc((min(100vw, 1120px) - 2rem) /2)",
};

export function toKeyScaleDown(values, base = {}) {
  return values.reduce((prev, current) => {
    return {
      ...prev,
      [current / 4]: current <= 1 ? current : `${current / 16}rem`,
    };
  }, base);
}

export function buttonSize(style) {
  return {
    small: {
      variant: "text.button3",
      py: 1.5,
      px: 3.5,
      ...style,
    },
    large: {
      variant: "text.button1",
      py: 2,
      px: 4,
      ...style,
    },
    __default: {
      variant: "text.button1",
      py: 2,
      px: 4,
      ...style,
    },
  };
}
