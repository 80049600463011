export const frameStyle = (labelBackground) => ({
  position: "relative",
  webkitUserSelect: "none",
  userSelect: "none",
  'input:not(:placeholder-shown):not([type="hidden"]) + .placeholder,\
   .withValue + .placeholder,\
   input:focus + .placeholder\
   select:not(:valid) + .placeholder,\
   .withValue + .placeholder,\
   select:focus + .placeholder': {
    fontSize: 3,
    transform: "translateY(-18px)",
    background: labelBackground,
  },

  "input:focus:not(:placeholder-shown) + .placeholder,\
   input:focus + .placeholder": {
    color: "primary",
  },
});

export const labelStyle = (alwaysTop) => ({
  position: "absolute",
  top: 2,
  left: 3,
  zIndex: "low",
  px: 1,
  background: "transparent",
  width: "initial",
  transition: "0.2s ease-out",
  cursor: "text",
  ...(alwaysTop
    ? { fontSize: 3, transform: "translateY(-18px)", background: "background" }
    : {}),
});
