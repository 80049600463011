import axpAxios from "utils/axpAxios";

const fetchBookings = (setBookingList, setBookingListError, setLoading) => {
  setLoading(true);
  axpAxios
    .get("/guest/v1/bookings", {})
    .then((response) => {
      setBookingList(response.data.bookings);
    })
    .catch((error) => {
      setBookingListError(error);
    })
    .finally(() => {
      setLoading(false);
    });
};


export default fetchBookings;
