import axios from "axios";
import { getAxpApiUrl } from "./envvars";

const instance = axios.create({
  baseURL: getAxpApiUrl(),
  withCredentials: true,
});

export const TOKEN_KEY = "wla_jwt";

export function updateAxp({ token } = {}) {
  if (!token) {
    const { common } = instance.defaults.headers;
    localStorage.removeItem(TOKEN_KEY);
    delete common["auth"];
    delete common["authType"];
    return;
  }
  const { common } = instance.defaults.headers;
  localStorage.setItem(TOKEN_KEY, token);
  common["auth"] = token;
  common["authType"] = "JWT";
}

export default instance;
