import React from "react";
import { Divider, Flex } from "theme-ui";

const DividerText = ({ text, styles }) => {
  return (
    <Flex
      sx={{
        variant: "text.caption",
        color: "lineDetail",
        gap: "16px",
        ...styles,
      }}
    >
      <Divider sx={{ flex: 1 }} />
      <span>{text}</span>
      <Divider sx={{ flex: 1 }} />
    </Flex>
  );
};

export default DividerText;
