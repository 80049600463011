import fetchBookings from "./fetchBookings";
import { useEffect, useState, useContext, useMemo } from "react";
import { AuthContext } from "../Authentication/AuthProvider";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";

const useFetchBookings = () => {
  const [bookingList, setBookingList] = useState([]);
  const [bookingListError, setBookingListError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { isSignedIn } = useContext(AuthContext);

  useEffect(() => {
    fetchBookings(setBookingList, setBookingListError, setLoading);
  }, [isSignedIn]);

  const { allStrapiProperty } = useStaticQuery(graphql`
    query StrapiPropertiesQuery {
      allStrapiProperty {
        edges {
          node {
            axp_location_id
            code
            name
            slug
            city {
              name
              slug
            }
            contactInfo: contact_info {
              address {
                city
                country
              }
            }
            heroImage: hero_image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `);

  const propertiesList = allStrapiProperty.edges.map(({ node }) => ({
    ...node,
    heroImage: getImage(node.heroImage?.localFile),
  }));

  const today = new Date().toISOString().split("T")[0];
  const currentAndFutureBookings = useMemo(() => bookingList
    .map((booking) => {
      const property = propertiesList.find(
        (property) => property.code === booking.propertyShortCode
      );
      return {
        ...booking,
        location: property,
      };
    })
    .filter((booking) => {
      return booking.endAt >= today && booking.location;
    })
    .sort((a, b) => {
      return a.startAt.localeCompare(b.startAt);
    }), [bookingList])

  return {
    bookingList,
    bookingListError,
    loading,
    currentAndFutureBookings,
    propertiesList,
  };
};

export default useFetchBookings;
