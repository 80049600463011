/* eslint-disable react/display-name */
import React from "react";

import Root from "./src/Root";

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.GA_TRACKING_ID}`}
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
      <Root>{element}</Root>
    </>
  );
};
