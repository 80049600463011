import { navigate } from "gatsby";
import React, { useState, createContext, useEffect } from "react";
import axpAxios, { TOKEN_KEY } from "utils/axpAxios";
import { getBrandId } from "utils/envvars";
import { updateAxp } from "../utils/axpAxios";
import { firebaseLogOut } from "./firebase";

export const AuthContext = createContext({
  profile: null,
  signIn: () => {},
  isSignedIn: false,
  signOut: () => {},
  refreshProfile: () => {},
});

export function axpTokenWithFirebase(data) {
  if (!data?.token) throw new Error("The token doesn't exist");
  return axpAxios
    .post("/guest/v1/firebase", {
      ...data,
      brand_id: getBrandId(),
    })
    .then((res) => {
      updateAxp(res.data);
    });
}

export const fetchProfile = async () => {
  const response = await axpAxios.get("guest/v1/current_profile", {
    headers: {
      os: "web",
    },
  });
  return response.data;
};

export const bookingSignIn = async (data) => {
  await axpAxios.post("/guest/v1/session", {
    ...data,
    brandId: getBrandId(),
  });

  return fetchProfile();
};

export const loyaltySignIn = async (data) => {
  await axpAxios.post("/guest/v1/session/identity_provider", {
    brandId: getBrandId(),
    identifier: data.identifier.replace(/\+/g, "%2B"),
    password: data.password,
  });

  return fetchProfile();
};

export const signOutFrom = async () => {
  return await Promise.all([
    axpAxios({
      url: "/guest/v1/session",
      method: "DELETE",
      withCredentials: true,
    }),
    firebaseLogOut(),
  ]);
};

export const AuthProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(false);

  const signOut = async () => {
    signOutFrom().then(() => {
      setProfile(null);
      setIsSignedIn(false);
      localStorage.removeItem(TOKEN_KEY);
      updateAxp();
      navigate("/");
    });
  };

  const signIn = async (data) => {
    setProfile(data);
    setIsSignedIn(true);
  };

  const refreshProfile = async () => {
    fetchProfile().then((data) => {
      setProfile(data);
    });
  };

  return (
    <AuthContext.Provider
      value={{ profile, isSignedIn, signIn, signOut, refreshProfile }}
    >
      {children}
    </AuthContext.Provider>
  );
};
