import "./fonts.css";
import { colors, shadows } from "./colors";
import { text, fontSizes, fontWeights, fonts, lineHeights } from "./typography";
import forms from "./forms";
import { space, sizes, radii, zIndices } from "./sizeAndSpace";
import buttons from "./buttons";
import alerts from "./alerts";
import links from "./links";
import cards from "./cards";
import pills from "./pills";
import images from "./images";
import "./body.css";

const themeBase = {
  config: {
    initialColorModeName: "light",
  },
  // Mobile: 0-599
  // Tablet: 600-1023
  // Desktop 1024 <
  breakpoints: ["600px", "1024px"],

  // Colors
  colors,
  shadows,

  // Typography
  fonts,
  fontWeights,
  lineHeights,
  fontSizes,
  text,

  // Space and Size
  space,
  sizes,
  radii,
  zIndices,

  // Components
  forms,
  buttons,
  alerts,
  links,
  cards,
  pills,
  video: {
    display: "block",
    width: "full",
    height: "full",
    objectFit: "cover",
    objectPosition: "center",
    overflow: "hidden",
  },
  images,

  // System styles
  styles: {
    root: {
      "--line-height": 1.5,
      background: "background",
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    hr: {
      borderColor: "lineDetail",
    },
    // these hx styles are not working for some reason
    h1: {
      variant: "text.heading1",
    },
    h2: {
      variant: "text.heading2",
    },
    h3: {
      variant: "text.heading3",
    },
    h4: {
      fontSize: "40px",
      variant: "text.heading4",
    },
    h5: {
      variant: "text.heading5",
    },
    h6: {
      variant: "text.heading6",
    },
    a: {
      variant: "text.subtitle1",
      color: "primary",
      textDecoration: "none",
    },
  },
};

export default themeBase;
