import { useCallback, useEffect, useRef, useState } from "react";

function useTempState(seconds = 3, baseValue = null) {
  const [state, setState] = useState(baseValue);
  const timeoutRef = useRef();

  useEffect(() => () => {
    clearTimeout(timeoutRef.current);
  });

  const changeOnTime = useCallback(
    (val) => {
      setState(val);
      if (val !== baseValue) {
        if (timeoutRef.current !== undefined) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          setState(baseValue);
          timeoutRef.current = undefined;
        }, seconds * 1000);
      }
    },
    [setState]
  );

  return [state, changeOnTime];
}

export default useTempState;
