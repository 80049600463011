export function getBrandId() {
  return process.env.GATSBY_BRAND_ID.trim();
}

export function getAxpApiUrl() {
  return process.env.GATSBY_AXP_API_URL.trim();
}

export function getAxpFEUrl() {
  return process.env.GATSBY_AXP_FE_URL.trim();
}

export function getGoogleMapApiKey() {
  return process.env.GATSBY_GOOGLE_MAP_API.trim();
}
