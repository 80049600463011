import React from "react";
import { Flex, Grid, Paragraph, Box } from "theme-ui";

import BrandLogo from "components/BrandLogo";
import Image from "components/Image";
import Link from "components/Link";

const Layout = ({ children, image }) => {
  return (
    <Grid
      as="main"
      sx={{
        m: "auto",
        gridTemplateColumns: "3fr 4fr",
        height: "screen-h",
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          mx: "auto",
          mt: 10,
          gridColumn: ["span 2", null, "1"],
          width: "100%",
          maxWidth: 105,
          px: 8,

          position: "relative",
        }}
      >
        <BrandLogo
          width={15}
          objectFit="scale-down"
          sx={{
            mr: "auto",
            mb: 10,
          }}
        />
        {children}
        <Paragraph
          sx={{
            variant: "text.body2",
            mt: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          By continuing, you agree to our
          <Link
            sx={{
              variant: "links.text.small",
              ml: 1,
            }}
            to="/"
          >
            Terms of Use.
          </Link>
        </Paragraph>
      </Flex>
      {image ? (
        <Image
          image={image}
          alt="Authentication Image"
          sx={{
            objectFit: "cover",
            display: ["none", "none", "initial"],
          }}
        />
      ) : (
        <Box sx={{ height: "full", backgroundColor: "grey.300" }}></Box>
      )}
    </Grid>
  );
};

export default Layout;
