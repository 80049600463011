import { SIZES_VALUES, CUSTOM_SIZES_VALUES, toKeyScaleDown } from "./utils";

export const space = toKeyScaleDown(SIZES_VALUES, {
  contentSpace: "max(calc(50vw - 35rem), 1.5rem)",
  contentSpacePlusGap: "max(calc(50vw - 35rem), 2.5rem)",
  columnMargin: "max(calc(50vw - 45rem), 0rem)",
});

export const sizes = toKeyScaleDown(SIZES_VALUES, CUSTOM_SIZES_VALUES);

export const radii = toKeyScaleDown([4, 12, 16], {
  rounded: "1000px",
  full: "100%",
});

export const zIndices = {
  negative: -1,
  low: 10,
  "stiky-menu": 15,
  menu: 20,
  nav: 110,
  backdrop: 200,
  modal: 250,
  "modal-button": 270,
};
