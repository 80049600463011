import React from "react";
import { Box, Label } from "theme-ui";
import { frameStyle, labelStyle } from "./Frame.styles";

const Frame = ({
  label,
  name,
  children,
  helper,
  error,
  styles,
  alwaysTop,
  labelBackground = "background",
}) => (
  <Box sx={{ ...frameStyle(labelBackground), ...styles }}>
    {children}
    <Label
      variant="caption"
      className="placeholder"
      sx={labelStyle(alwaysTop, labelBackground)}
      htmlFor={name}
    >
      {label}
    </Label>
    {helper && (
      <Label variant="caption" sx={{ color: "textSecondary" }}>
        {helper}
      </Label>
    )}
  </Box>
);

export default Frame;
