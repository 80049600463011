import React, { useContext, useLayoutEffect, useState } from "react";
import {
  AuthContext,
  AuthProvider,
  axpTokenWithFirebase,
  fetchProfile,
} from "./Authentication/AuthProvider";
import { LoadingIndicator } from "components";
import { getToken, isLogedIn } from "./Authentication/firebase";
import "./styles/fix_svg.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

function Root({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const { signIn } = useContext(AuthContext);

  useLayoutEffect(() => {
    setIsLoading(true);

    const fbPromise = isLogedIn()
      .then(getToken)
      .then(axpTokenWithFirebase)
      .then(fetchProfile)
      .then(signIn)
      .catch((e) => {
        if (e.message === "firebase/user-undefined") {
          return;
        }
        console.error(e);
        throw new Error(e);
      });

    const axpPromise = fetchProfile()
      .then(signIn)
      .catch((error) => {
        if (error?.response?.status === 401) {
          return;
        }
        console.log("error", error);
      });

    Promise.all([fbPromise, axpPromise])
      .catch((e) => console.error(e))
      .finally(() => setIsLoading(false));
  }, []);

  return isLoading ? <LoadingIndicator sx={{ height: "100vh" }} /> : children;
}

function Providers({ children }) {
  return (
    <AuthProvider>
      <Root>{children}</Root>
    </AuthProvider>
  );
}

export default Providers;
