import React, { useEffect, useState } from "react";
import FocusLock from "react-focus-lock";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Paragraph,
} from "theme-ui";
import CloseIcon from "@material-ui/icons/Close";

import { motion, AnimatePresence } from "framer-motion";
import { navigate } from "gatsby";
import debounce from "lodash/debounce";

import * as styles from "components/ReadMoreModal/ReadMoreModal.styles";
import Image from "components/Image";

import { useClickOutside, useVariantByBreakpoint } from "utils";

export default function ReadMoreModal({
  section,
  title,
  subtitle,
  image,
  onClose,
  description,
  url,
}) {
  const [isOpened, setIsOpened] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const close = () => {
    setIsVisible(false);
    onClose();
  };
  const variantSelector = useVariantByBreakpoint();
  const clickOutsideRef = useClickOutside(onClose);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === "Escape") {
        close();
      }
    }

    const handleKeyDownDebounced = debounce(handleKeyDown);

    document.addEventListener("keydown", handleKeyDownDebounced);
    return () => {
      document.removeEventListener("keydown", handleKeyDownDebounced);
    };
  }, []);

  return (
    <Box sx={styles.modalContainer}>
      <AnimatePresence>
        <FocusLock returnFocus disabled={!isOpened}>
          {isVisible && (
            <motion.div
              sx={styles.modalContainer}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              onAnimationComplete={(definition) => {
                if (definition.opacity === 1) {
                  setIsOpened(true);
                }
                if (definition.opacity === 0) {
                  onClose();
                }
              }}
            >
              <Box
                ref={clickOutsideRef}
                sx={{
                  width: ["90%", "70%", "40%"],
                  background: "background",
                  position: "fixed",
                  right: 0,
                  height: "100vh",
                  p: 10,
                  overflowY: "auto",
                }}
              >
                <Flex
                  sx={{ alignItems: "center", justifyContent: "space-between" }}
                >
                  <Heading>{section}</Heading>
                  <IconButton
                    variant="buttons.icon.light"
                    sx={{
                      padding: 0,
                      "&:hover": {
                        color: "primary.dark",
                      },
                    }}
                    onClick={onClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Flex>
                <Divider sx={{ my: 4 }} />
                {image && (
                  <Box
                    sx={{
                      mb: 6,
                      maxWidth: "520px",
                      maxHeight: "272px",
                      display: "flex",
                      marginX: "auto",
                    }}
                  >
                    <Image
                      style={{ width: "100%" }}
                      image={image}
                      alt="Modal image"
                    />
                  </Box>
                )}
                {title && (
                  <Heading
                    as="h4"
                    variant={variantSelector(["subtitle1", null, "heading6"])}
                    sx={{ mb: subtitle ? 1 : 4 }}
                  >
                    {title}
                  </Heading>
                )}
                {subtitle && (
                  <Paragraph
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 4 }}
                  >
                    {subtitle}
                  </Paragraph>
                )}
                {description && (
                  <Paragraph sx={{ mb: 4 }}>{description}</Paragraph>
                )}
                {url && (
                  <Button
                    sx={{
                      variant: "buttons.primary.small",
                    }}
                    onClick={() => navigate(url)}
                  >
                    Visit Website
                  </Button>
                )}
              </Box>
            </motion.div>
          )}
        </FocusLock>
      </AnimatePresence>
    </Box>
  );
}
