/** @jsx jsx */
import { GatsbyImage } from "gatsby-plugin-image";
import { jsx, Image as ThemeImage } from "theme-ui";

import isString from "lodash/isString";

const Image = ({ sx, image, ...props }) => {
  if (isString(image)) {
    return (
      <ThemeImage
        sx={{ variant: "images.primary", ...sx }}
        src={image}
        {...props}
      />
    );
  }
  return <GatsbyImage sx={sx} image={image} {...props} />;
};

export default Image;
