import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Image, useThemeUI } from "theme-ui";

const BrandLogo = ({ width = 0, sx, ...props }) => {
  const { logo } = useStaticQuery(graphql`
    query BrandLogoQuery {
      logo: strapiAppConfig {
        lightLogo: brand_logo_light {
          localFile {
            publicURL
          }
        }
        darkLogo: brand_logo_dark {
          localFile {
            publicURL
          }
        }
      }
    }
  `);
  const { colorMode } = useThemeUI();

  const url =
    colorMode === "dark"
      ? logo.darkLogo.localFile.publicURL
      : logo.lightLogo.localFile.publicURL;
  return (
    <Image
      sx={{
        width,
        ...sx,
      }}
      src={url}
      {...props}
    />
  );
};

export default BrandLogo;
