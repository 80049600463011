import contains from "document.contains";
import { useLayoutEffect, useRef } from "react";

function useClickOutside(callback) {
  const containerRef = useRef();
  const outsideClickRef = useRef();

  const removeMouseUpEvent = () => {
    document.removeEventListener("mouseup", outsideClickRef.current);
    outsideClickRef.current = null;
  };

  useLayoutEffect(() => {
    const callbackUp = (e) => {
      const isDescendantOfRoot =
        containerRef.current && contains(containerRef.current, e.target);

      if (!isDescendantOfRoot) {
        removeMouseUpEvent();
        callback();
      }
    };
    const callbackDown = (e) => {
      const isDescendantOfRoot =
        containerRef.current && contains(containerRef.current, e.target);
      if (!isDescendantOfRoot) {
        if (outsideClickRef.current) {
          removeMouseUpEvent();
        }
        outsideClickRef.current = callbackUp;
        document.addEventListener("mouseup", callbackUp);
      }
    };

    document.addEventListener("mousedown", callbackDown);

    return () => {
      document.removeEventListener("mousedown", callbackDown);
      if (outsideClickRef.current) {
        removeMouseUpEvent();
      }
    };
  }, []);

  return containerRef;
}

export default useClickOutside;
