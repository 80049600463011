function minuteFormat(mins) {
  if (mins !== 0) {
    return `:${mins}`;
  }
  return "";
}

export default function hourFormat(time) {
  const hour = parseInt(time.slice(0, 2));
  const minutes = minuteFormat(parseInt(time.slice(3, 5)));
  if (hour > 12) {
    return `${hour - 12}${minutes} PM`;
  }
  if (hour === 0) {
    return `${hour}${minutes} AM`;
  }
  if (hour === 12) {
    return `${hour}${minutes} PM`;
  }
  return `${hour}${minutes} AM`;
}
