export default {
  text: {
    __default: {
      variant: "text.subtitle1",
      display: "inline-flex",
      color: "primary",
      p: 0,
      textDecoration: "none",
      cursor: "pointer",
      "&:hover": {
        color: "primary.dark",
        borderColor: "primary.dark",
      },
    },
    small: {
      variant: "text.subtitle2",
      display: "inline-flex",
      color: "primary",
      p: 0,
      textDecoration: "none",
      cursor: "pointer",
      "&:hover": {
        color: "primary.dark",
      },
    },
  },
  base: {
    color: "primary",
  },
  nav: {
    variant: "buttons.text",
    borderRadius: [0, null, "rounded"],
    textDecoration: "none",
    cursor: "pointer",
  },
  button: {
    variant: "buttons.primary",
    textDecoration: "none",
    "&:hover": {
      color: "systemWhite",
      background: "primary.dark",
    },
  },
  circularButton: {
    borderColor: "primary",
    border: "1px solid",
    borderRadius: "10000px",
    width: "50px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "primary",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: "primary.dark",
      borderColor: "primary.dark",
    },
  },
  breadcrumb: {
    variant: "text.subtitle2",
    fontWeight: "400",
    color: "text.secondary",
    p: 0,
    textDecoration: "none",
    cursor: "pointer",
    "&.isSelected": {
      fontWeight: "700",
      color: "primary.dark",
    },
  },
};
