const form = {
  input: {
    display: "block",
    variant: "text.body",
    minHeight: 10,
    p: 2,
    borderRadius: 1,
    bg: "componentBG1",
    border: "1px solid",
    borderColor: "lineDetail",
    cursor: "text",
    "&:hover,&:focus": {
      outline: "none",
      borderColor: "primary",
    },
    ":placeholder-shown": {
      bg: "componentBG1",
    },
    search: {
      height: "auto",
      borderRadius: 1,
      boxShadow: "elevation3dp",
      border: "1px solid transparent",
      "&:hover,&:focus": {
        border: "1px solid rgba(160, 107, 34, 0.5)",
      },
    },
  },
  select: {
    variant: "forms.input",
    borderColor: "lineDetail",
    bg: "componentBG1",
    borderRadius: 1,
    "&:hover,&:focus": {
      outline: "none",
      borderColor: "primary",
    },
  },
  day: {
    position: "relative",
    zIndex: "low",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: 8,
    height: 8,
    p: 2,
    background: "transparent",
    border: "1px solid transparent",
    borderRadius: "full",
    textAlign: "center",
    color: "text",
    cursor: "pointer",
    "&::before, &::after": {
      content: `""`,
      position: "absolute",
      top: "-1px",
      zIndex: "negative",
    },
    "&.isSingleSelected": {
      backgroundColor: "primary",
      borderColor: "primary",
      color: "primary.contrastText",
    },
    "&.isSelected": {
      color: "primary.contrastText",
      "&::after": {
        backgroundColor: "primary",
        borderColor: "primary",
        width: 8,
        height: 8,
        borderRadius: "full",
      },
      "&::before": {
        width: 11,
        height: 8,
        background: "primary.activeMix",
      },
      "&.left::before, &.left::after": {
        left: "-1px",
      },
      "&.left::before": {
        borderTopLeftRadius: "rounded",
        borderBottomLeftRadius: "rounded",
      },
      "&.right::before, &.right::after": {
        right: "-1px",
      },
      "&.right::before": {
        borderTopRightRadius: "rounded",
        borderBottomRightRadius: "rounded",
      },
    },
    "&.isInMiddle": {
      color: "primary.dark",
      "::before": {
        left: "calc(50%)",
        transform: "translateX(-50%)",
        width: 12,
        height: 8,
        background: "primary.activeMix",
      },
    },
    "&.isToday::after": {
      width: "calc(2rem - 2px)",
      height: "calc(2rem - 2px)",
      borderRadius: "full",
      border: "1px solid",
      borderColor: "lineDetail",
    },
    "&:disabled": {
      color: "text.disabled",
    },
  },
};

export default form;
