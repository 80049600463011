import { toKeyScaleDown } from "./utils";

export const fonts = {
  body: "Oxygen-Regular",
  heading: "Volkhov-Regular",
  subtitle: "Oxygen-Bold",
  button: "Volkhov",
  input: "Oxygen-Regular",
};

export const fontWeights = {
  body: 400,
  heading: 400,
  bold: 700,
  button: 400,
  light: 300,
};

export const lineHeights = {
  body: 1.5,
  heading: 1.125,
};

export const fontSizes = toKeyScaleDown([
  10, 12, 14, 15, 16, 20, 24, 34, 48, 60, 96,
]);

export const text = {
  heading1: {
    color: "text.heading",
    fontFamily: "Volkhov-Regular",
    fontSize: 24,
    "--line-height": 1.2,
    lineHeight: "var(--line-height)",
    letterSpacing: "-1.5px",
  },
  heading2: {
    color: "text.heading",
    fontFamily: "Volkhov-Regular",
    fontSize: 15,
    "--line-height": 1.25,
    lineHeight: "var(--line-height)",
    letterSpacing: "-0.5px",
  },
  heading3: {
    color: "text.heading",
    fontFamily: "Volkhov-Regular",
    fontSize: 12,
    "--line-height": 1.3,
    lineHeight: "var(--line-height)",
    letterSpacing: "0px",
  },
  heading4: {
    color: "text.heading",
    fontFamily: "Volkhov-Regular",
    fontSize: 8.5,
    "--line-height": 1.4,
    lineHeight: "var(--line-height)",
    letterSpacing: "0px",
  },
  heading5: {
    color: "text.heading",
    fontFamily: "Volkhov-Regular",
    fontSize: 6,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0px",
  },
  heading6: {
    color: "text.heading",
    fontFamily: "Volkhov-Regular",
    fontSize: 5,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0px",
  },
  subtitle1: {
    color: "text.heading",
    fontFamily: "Oxygen-Bold",
    fontSize: 4,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.15px",
  },
  subtitle2: {
    color: "text.heading",
    fontFamily: "Oxygen-Bold",
    fontSize: 3.5,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.15px",
  },
  body1: {
    color: "text",
    fontFamily: "Oxygen-Regular",
    fontSize: 4,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.15px",
  },
  body2: {
    color: "text",
    fontFamily: "Oxygen-Regular",
    fontSize: 3.5,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.15px",
  },
  body3: {
    color: "text",
    fontFamily: "Oxygen-Regular",
    fontSize: 3,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.15px",
  },
  button1: {
    color: "primary.contrastText",
    fontFamily: "Volkhov-Regular",
    fontSize: 4,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.75px",
  },
  button2: {
    color: "primary.contrastText",
    fontFamily: "Volkhov-Regular",
    fontSize: 3.75,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.5px",
  },
  button3: {
    color: "primary.contrastText",
    fontFamily: "Volkhov-Regular",
    fontSize: 3.5,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.5px",
  },
  caption: {
    color: "text",
    fontFamily: "Oxygen-Light",
    fontSize: 3,
    "--line-height": 1.2,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.4px",
  },
  overline: {
    color: "text",
    fontFamily: "Oxygen-Regular",
    fontSize: 3,
    "--line-height": 2.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "1px",
  },
};
