import isNil from "lodash/fp/isNil";
import flow from "lodash/fp/flow";
import fromPairs from "lodash/fp/fromPairs";
import sortBy from "lodash/fp/sortBy";
import toPairs from "lodash/fp/toPairs";
import reduce from "lodash/fp/reduce";
import isArray from "lodash/fp/isArray";
import isEmpty from "lodash/fp/isEmpty";

const ORDERED_DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const filterOpeningHour = reduce((acc, [day, hours]) => {
  if (isNil(hours) || !isArray(hours)) return acc;

  const newHours = hours.filter(({ opens_at }) => opens_at);
  if (isEmpty(newHours)) return acc;

  return [...acc, [day, newHours]];
}, []);

const groupOpeningHours = flow(
  toPairs,
  filterOpeningHour,
  sortBy(([day]) => ORDERED_DAYS.indexOf(day)),
  fromPairs
);

export default groupOpeningHours;
