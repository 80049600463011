import React from "react";
import { Box, Flex, Grid, Text } from "theme-ui";
import GoogleMap from "components/GoogleMap";
import {
  emailUrl,
  generateMarkerForLocation,
  getExternalMapUrl,
  phoneUrl,
} from "utils";
import { Link } from "components";
import {
  LocationOn,
  OpenInNew,
  Phone,
  PhoneOutlined,
  Public,
  Email,
} from "@material-ui/icons";
import LinkWithIcon from "components/LinkWithIcon";

const InformationItem = ({ icon: Icon, children }) => (
  <Flex sx={{ alignItems: "center", gap: "8px" }}>
    <Text
      sx={{
        display: "inline-flex",
        justifyItems: "center",
        mr: 2,
        color: "text.secondary",
        fontSize: 5,
        flexShrink: 0,
      }}
    >
      <Icon />
    </Text>

    {children}
  </Flex>
);

const MapSection = ({ locationInfo, ...props }) => {
  const { location, address, website, phone, email } = locationInfo.contactInfo;

  return (
    <Box {...props}>
      <GoogleMap
        coordinates={location}
        marker={generateMarkerForLocation(locationInfo)}
        sx={{
          height: [90, 120],
        }}
      />
      <Grid
        columns={[1, 2]}
        row={["initial", 2]}
        sx={{
          gridAutoFlow: "row",
          mt: 6,
          columnGap: 20,
        }}
      >
        {address?.address_line_1 && (
          <InformationItem icon={LocationOn}>
            {address.address_line_1}
            {address.address_line_2 && ", " + address.address_line_2},
            <br />
            <LinkWithIcon
              to={getExternalMapUrl(location)}
              target="_blank"
              icon={OpenInNew}
            >
              Open in Google Maps
            </LinkWithIcon>
          </InformationItem>
        )}
        {website && (
          <InformationItem icon={Public}>
            <LinkWithIcon target="_blank" icon={OpenInNew} to={website}>
              <Text
                sx={{
                  overflowWrap: "anywhere",
                  mr: "10px",
                }}
              >
                Visit Website
              </Text>
            </LinkWithIcon>
          </InformationItem>
        )}
        {phone && (
          <InformationItem icon={PhoneOutlined}>
            <Link to={phoneUrl(phone)}>Call</Link>
          </InformationItem>
        )}
        {email && (
          <InformationItem icon={Email}>
            <Link to={emailUrl(email)}>Email</Link>
          </InformationItem>
        )}
      </Grid>
    </Box>
  );
};

export default MapSection;
