/** @jsx jsx */
import { jsx, Link as ThemeUILink } from "theme-ui";
import { Link as GatsbyLink } from "gatsby";

const REGEX = /^((html)|(mailto\:)|(tel\:)|(http(s)?\:))/g;

function Link({ to, variant = "links.text", ...props }) {
  const sx = {
    variant,
    textDecoration: "none",
    ...props.sx,
  };

  if (REGEX.test(to)) {
    const aProps = {
      ...props,
      target: "_blank",
      rel: props.target === "_blank" && "noopener noreferrer",
    };
    return <a href={to} {...aProps} sx={sx} />;
  }

  if (to) {
    return (
      <GatsbyLink to={to} {...props} activeClassName="is-active" sx={sx} />
    );
  }

  return <ThemeUILink {...props} sx={sx} />;
}

export default Link;
