import React, { useEffect, useState } from "react";
import AuthForm from "./components/AuthForm";
import Layout from "./components/Layout";
import { getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

export const LOCALSTORAGE_SINGIN_KEY = "backTo";

const SignInPage = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiBrandHome {
        login_screen_hero_image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const [redirectTo, setRedirectTo] = useState();

  useEffect(() => {
    setRedirectTo(localStorage.getItem(LOCALSTORAGE_SINGIN_KEY));
    localStorage.removeItem(LOCALSTORAGE_SINGIN_KEY);
  }, []);

  return (
    <Layout
      image={getImage(
        data.strapiBrandHome?.login_screen_hero_image?.localFile
          ?.childImageSharp?.gatsbyImageData
      )}
    >
      <AuthForm redirectUrl={redirectTo} />
    </Layout>
  );
};

export default SignInPage;
