/** @jsx jsx */
import { Alert as BaseAlert, Paragraph, jsx, Text } from "theme-ui";
import {
  CheckCircleOutline,
  ErrorOutline,
  InfoOutlined,
  WarningOutlined,
  Close,
} from "@material-ui/icons";

const IconByVariant = ({ variant }) => {
  switch (variant) {
    case "success":
      return <CheckCircleOutline />;
    case "info":
      return <InfoOutlined />;
    case "warning":
      return <WarningOutlined />;
    case "error":
      return <ErrorOutline />;
    default:
      return <InfoOutlined />;
  }
};

const Alert = ({ data, variant, styles, onClose }) => {
  return (
    <BaseAlert
      variant={variant}
      sx={{
        display: "grid",
        gridTemplateColumns: "24px 1fr 26px",
        gridAutoFlow: "row",
        columnGap: "8px",
        ...styles,
      }}
    >
      <IconByVariant variant={variant} />
      <Paragraph
        variant="subtitle1"
        sx={{ gridColumn: "2", lineHeight: "28px", color: "currentColor" }}
      >
        {data?.title}
      </Paragraph>
      <Paragraph
        variant="body2"
        sx={{ gridColumn: "2", color: "currentColor" }}
      >
        {data?.description}
      </Paragraph>
      {data?.helper && (
        <Text
          variant="caption"
          sx={{
            gridColumn: "2",
            mt: 2,
            lineHeight: "20px",
            color: "currentColor",
          }}
        >
          {data.helper}
        </Text>
      )}
      {onClose && (
        <Close
          sx={{ gridColumn: "3", gridRow: "1", color: "currentColor" }}
          onClick={onClose}
        />
      )}
    </BaseAlert>
  );
};

export default Alert;
