import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  setPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { initializeApp } from "firebase/app";

let auth = null;
let initialized = false;

if (process.env.FIREBASE_API_KEY) {
  // Firebase inizialization
  const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGIN_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  };
  const app = initializeApp(firebaseConfig);
  auth = getAuth(app);

  auth.onAuthStateChanged(() => {
    initialized = true;
  });
}

function formatIdToken(idToken) {
  return {
    token: idToken,
  };
}

export const ERROR_NOT_VERIFIED = "Emails is not verified";

export async function signInGoogle() {
  const provider = new GoogleAuthProvider();
  try {
    await setPersistence(auth, browserSessionPersistence);
    const result = await signInWithPopup(auth, provider);

    return result.user.getIdToken(true).then((idToken) => ({ token: idToken }));
  } catch (error) {
    // const credential = GoogleAuthProvider.credentialFromError(error);
    console.log(`Error: ${error}`);
  }
}

export async function signInApple() {
  const provider = new OAuthProvider("apple.com");
  try {
    await setPersistence(auth, browserSessionPersistence);
    const result = await signInWithPopup(auth, provider);

    return result.user.getIdToken(true).then(formatIdToken);
  } catch (error) {
    // const credential = OAuthProvider.credentialFromError(error);
    console.log(`Error: ${error}`);
  }
}

export async function signUpEmail({ email, password }) {
  try {
    await setPersistence(auth, browserSessionPersistence);
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    await sendEmailVerification(user);
  } catch (e) {
    console.error(e);
  }
}

export async function signInEmail({ email, password }) {
  await setPersistence(auth, browserSessionPersistence);
  const { user } = await signInWithEmailAndPassword(auth, email, password);

  if (!user.emailVerified) {
    throw new Error(ERROR_NOT_VERIFIED);
  }
  return {
    token: user.accessToken,
  };
}

export async function sendVerificationEmail() {
  await sendEmailVerification(auth.currentUser);
}

export async function userExist(email) {
  const signInMethods = await fetchSignInMethodsForEmail(auth, email);

  // If there is no methods to login is because the user doesn't exist in firebase
  return signInMethods.length !== 0;
}

export function isLogedIn() {
  return new Promise((resolve) => {
    if (initialized) {
      resolve(initialized);
      return;
    }
    auth.onAuthStateChanged((user) => {
      resolve(!!user);
    });
  });
}

export async function firebaseLogOut() {
  return auth.signOut();
}

export async function getToken() {
  if (!auth?.currentUser) throw new Error("firebase/user-undefined");
  return auth.currentUser.getIdToken(true).then(formatIdToken);
}
